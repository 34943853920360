import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Divider, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import {
  formatPayBoostPercentage,
  getActiveStreakByDate,
  getPayBoostActiveStreaks,
  isDateWithinStreakValidity,
  StreakPayModifierBar,
  useGetWorkerActiveStreaks,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks";
import { type Worker } from "@src/appV2/Worker/api/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { useRouteMatch } from "react-router-dom";

import { type ShiftBlockDetail } from "../api/useGetShiftBlock";
import { SHIFT_BLOCK_ACTIONS, transformIntoShiftBlockWithDetails } from "../utils";
import { BookShiftBlockButton } from "./BookShiftBlockButton";
import { FacilityChatButtonContainer } from "./FacilityChatButtonContainer";
import { PriorityAccessAlert } from "./PriorityAccessAlert";
import { ShiftBlockFacilityDetails } from "./ShiftBlockFacilityDetails";
import { ShiftBlockHeader } from "./ShiftBlockHeader";
import { ShiftBlockShiftDetails } from "./ShiftBlockShiftDetails";

interface ShiftBlockDetailProps {
  worker: Worker;
  shiftBlock: ShiftBlockDetail;
  refetchShiftBlock: UseQueryResult<ShiftBlockDetail>["refetch"];
}

export function ShiftBlockDetails(props: ShiftBlockDetailProps) {
  const { worker, shiftBlock, refetchShiftBlock } = props;
  const { path: currentRouterUrl } = useRouteMatch();
  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const { data: activeStreaksResponse } = useGetWorkerActiveStreaks(
    {
      agentId: worker.userId,
      filter: {
        facilityIds: [shiftBlock.data.attributes.facilityId],
      },
    },
    { enabled: isStreaksEnabledGlobally && isDefined(worker.userId) }
  );
  const activePayboostStreaks = getPayBoostActiveStreaks(activeStreaksResponse?.data);
  const currentActiveStreak = getActiveStreakByDate({
    streaks: activePayboostStreaks,
    date: new Date(),
  });

  const shiftBlockWithMappedDetails = transformIntoShiftBlockWithDetails(
    shiftBlock.data,
    shiftBlock.included
  );
  const {
    id: shiftBlockId,
    facility,
    shifts,
    isShiftBlockAssigned,
    priorityAccess,
  } = shiftBlockWithMappedDetails;

  const shiftBlockShiftStreaks = activePayboostStreaks.filter((streak) =>
    shifts.some((shift) => isDateWithinStreakValidity(shift.start, streak))
  );

  useLogEffect(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
    action: SHIFT_BLOCK_ACTIONS.BLOCK_DETAILS_VIEWED,
    shiftBlockId,
    facilityId: facility.id,
    isShiftBlockAssigned,
    location: currentRouterUrl,
    priorityAccess,
  });

  const hasPriorityAccessOrStreaks =
    (isDefined(priorityAccess) && isDefined(priorityAccess.priorityTill)) ||
    isDefined(currentActiveStreak) ||
    shiftBlockShiftStreaks.length > 0;

  return (
    <Stack spacing={2}>
      <ShiftBlockHeader
        shiftBlockWithDetails={shiftBlockWithMappedDetails}
        agentTimezone={worker.tmz}
      />

      {hasPriorityAccessOrStreaks && (
        <Stack spacing={1}>
          {isDefined(priorityAccess) && isDefined(priorityAccess.priorityTill) && (
            <PriorityAccessAlert priorityAccessTill={priorityAccess.priorityTill} />
          )}

          {(isDefined(currentActiveStreak) || shiftBlockShiftStreaks.length > 0) &&
            isStreaksEnabledGlobally && (
              <StreakPayModifierBar
                streakPayDescription={`You're earning ${formatPayBoostPercentage(
                  Math.max(
                    ...shiftBlockShiftStreaks.map((streak) => streak.attributes.payBoostPercentage)
                  )
                )} more on certain shifts because you worked more than ${Math.min(
                  ...shiftBlockShiftStreaks.map(
                    (streak) => streak.attributes.minHoursForActiveStreak
                  )
                )} hours in the week prior to the shift.`}
              />
            )}
        </Stack>
      )}
      <ShiftBlockFacilityDetails facility={facility} isShiftBlockAssigned={isShiftBlockAssigned} />
      {isDefined(shiftBlock.data.attributes.notesForProfessionals) && (
        <>
          <Stack spacing={0.5}>
            <Text
              sx={{
                fontWeight: 600,
              }}
            >
              Block Information
            </Text>
            <Text
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.primary,
                opacity: 0.8,
              }}
            >
              {shiftBlock.data.attributes.notesForProfessionals}
            </Text>
          </Stack>

          <Divider />
        </>
      )}
      {isDefined(worker) && (
        <FacilityChatButtonContainer
          shiftBlockId={shiftBlockId}
          agentId={worker.userId}
          facilityId={facility.id}
          isShiftBlockAssigned={isShiftBlockAssigned}
        />
      )}

      <ShiftBlockShiftDetails
        shiftBlockWithDetails={shiftBlockWithMappedDetails}
        agentTimezone={worker.tmz}
        streaks={activePayboostStreaks}
      />

      {!isShiftBlockAssigned && (
        <Box sx={{ position: "absolute", bottom: "24px", left: "24px", right: "24px" }}>
          <BookShiftBlockButton
            facilityId={shiftBlock.data.attributes.facilityId}
            agentId={worker.userId}
            shiftBlockId={shiftBlock.data.id}
            shifts={shifts}
            blockAgentRequirement={shiftBlock.data.attributes.agentReq}
            instantBookPreferredReasons={
              shiftBlock.data.attributes.instantBook?.preferredReasons ?? []
            }
            onBookingShiftBlock={refetchShiftBlock}
          />
        </Box>
      )}
    </Stack>
  );
}
