import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import { Chip, Stack } from "@mui/material";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { type Worker } from "@src/appV2/Worker/api/types";

import { useEnableDistanceFiltering } from "../hooks/useEnableDistanceFiltering";
import { ShiftBlockDistanceFilterDialog } from "./ShiftBlockDistanceFilterDialog";
import { ShiftLicenseBlockFilterDialog } from "./ShiftBlockLicenseFilterDialog";

interface ShiftBlockFilterProps {
  worker: Worker;
}

export function ShiftBlockFilter(props: ShiftBlockFilterProps) {
  const { worker } = props;

  const distanceFilterModalState = useModalState();
  const licenseFilterModalState = useModalState();
  const { filters, setFilters } = useWorkerPreferences();

  const { enableDistanceFiltering } = useEnableDistanceFiltering();

  return (
    <>
      <Stack direction="row" spacing={1}>
        {isDefined(worker.licensesData) && isDefined(filters) && isDefined(filters.license) && (
          <Chip
            color="primary"
            icon={<FilterAltOutlined />}
            aria-label="Update License"
            label={<b>{filters.license}</b>}
            onClick={() => {
              licenseFilterModalState.openModal();
            }}
          />
        )}
        {isDefined(filters) && isDefined(filters.distance) && (
          <Chip
            color="primary"
            icon={<FilterAltOutlined />}
            aria-label="Update Distance"
            label={enableDistanceFiltering ? <b>{filters.distance} miles</b> : <b>Any distance</b>}
            onClick={() => {
              distanceFilterModalState.openModal();
            }}
          />
        )}
      </Stack>
      {isDefined(filters) && (
        <>
          <ShiftLicenseBlockFilterDialog
            modalState={licenseFilterModalState}
            agentLicenses={worker.licensesData}
            defaultValues={filters}
            onSubmit={setFilters}
          />
          <ShiftBlockDistanceFilterDialog
            modalState={distanceFilterModalState}
            defaultValues={filters}
            onSubmit={setFilters}
          />
        </>
      )}
    </>
  );
}
